import React, {useState, useEffect} from 'react'
import { Layout, Navbar, Admin } from '../components'

const Appointments = () => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(()=>{
        setHasMounted(true)
    },[])
    if(!hasMounted)return null
    return (
        <Layout>
            <Navbar/>
            <Admin/>
        </Layout>
    )
}

export default Appointments